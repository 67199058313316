import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-five'
import Footer from '../containers/layout/footer/footer-one'
import HeroArea from '../containers/index-propertypac/hero-area'
import AboutArea from '../containers/index-propertypac/about-area'
import ClientsArea from '../containers/index-propertypac/clients-area'
import ContactFormArea from '../containers/index-propertypac/contact-form-area'
import ContactInfoArea from '../containers/global/contact-area/contact-two'
import SectionOne from '../containers/elements/client-logo/section-one'
import FrontPageServices from '../containers/elements/box-large-image/section-three'
import SectionTwo from '../containers/elements/testimonials/section-two'
import ResourcesArea from '../containers/about-us/resources-area'
import SmoothTransitionSlider from '../containers/elements/flexible-image-slider/smooth-transition-slider'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Property Maintenance in Chester" />
    <Header />
    <main className="site-wrapper-reveal">
      <HeroArea />
      <AboutArea />
      <FrontPageServices />
      <SectionOne />
      <ClientsArea />
      <ResourcesArea />
      <ContactFormArea />
      <ContactInfoArea />
      <SectionTwo />
      <SmoothTransitionSlider />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
